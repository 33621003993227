<template class="about-us">
   <v-container fluid class="pa-0">
      <v-container fluid class="pa-0 bg-about-us fill-height">
         <v-row>
            <v-col>
               <div class="d-flex justify-center">
                  <v-img
                     v-if="$vuetify.breakpoint.smAndUp"
                     class="pt-8"
                     eager
                     :src="assets.aboutUs.aboutUsFill"
                     contain
                     style="max-width:90vw"
                  >
                  </v-img>
                  <v-img v-else class="pt-8" eager :src="assets.aboutUs.aboutUsMob3" contain style="max-width:90vw">
                  </v-img>
               </div>
            </v-col>
         </v-row>
         <v-row>
            <v-col cols="12" md="6" class="pt-8"
               ><v-img
                  class="imageAboutUs"
                  eager
                  :src="assets.aboutUs.authors"
                  contain
                  max-height="fit-content"
                  style="max-width:100%"
               ></v-img>
            </v-col>
            <v-col cols="12" md="6">
               <p class="my-0 my-sm-5 px-6 body-size">
                  As the creators of Red Sparrow for Autism, it is our heart to bless families and the wider community.
                  <br />
                  Our vision is to help Lower Anxiety in Everyday Life by providing engaging tools to equip children
                  with relevant life skills, and help them navigate each new challenge with confidence and calmness.
                  <br />
                  We hope our Interactive Digital Experiences bring you freedom to discover new possibilities.
                  <br />
                  - Melissa and Jo-anne
               </p>

               <v-row class="mt-2 mt-sm-6">
                  <v-col cols="12"
                     ><p class="my-0  px-6 body-size font-weight-bold">
                        Lowering anxiety in everyday life
                     </p></v-col
                  >
                  <v-col cols="12" sm="8">
                     <p class="px-6">
                        We’re dedicated to providing quality digital experiences to enable families to support those
                        with Autism
                     </p>
                  </v-col>
                  <!-- <v-col cols="12" sm="4">
                     <div class="d-flex justify-center">
                        <v-img :src="assets.icons.support" style="max-width: 30%;"></v-img>
                     </div>
                  </v-col> -->
               </v-row>

               <v-row class="mt-sm-1">
                  <v-col cols="12">
                     <p class="my-1 mb-sm-1 px-6 body-size font-weight-bold">Empowering others</p>
                  </v-col>
                  <v-col cols="12" sm="8">
                     <p class="px-6">
                        We seek to empower others to achieve their goals, whether great or small with the help of our
                        digital products.
                     </p>
                  </v-col>
                  <!-- <v-col ccols="12" sm="4">
                     <div class="d-flex justify-center">
                        <v-img :src="assets.icons.empower" style="max-width: 30%;"></v-img>
                     </div>
                  </v-col> -->
               </v-row>

               <v-row class="mt-sm-1">
                  <v-col cols="12">
                     <p class="my-4 mt-sm-1 mb-sm-4 px-6 body-size font-weight-bold">Our Values</p>
                  </v-col>
                  <v-col cols="12" sm="8">
                     <p class="px-6">
                        Making the world a better place through positive actions, listening, caring, loving and being
                        generous. Always thinking of our user’s needs first.
                     </p>
                  </v-col>
                  <!-- <v-col cols="12" sm="4">
                     <div class="d-flex justify-center">
                        <v-img class="iconOurvalues" :src="assets.icons.ourvalues" style="max-width: 30%;"></v-img>
                     </div>
                  </v-col> -->
               </v-row>
            </v-col>
         </v-row>
         <!--Quote-->
         <v-row class="d-flex align-center justify-center my-10">
            <v-spacer />
            <v-col cols="11" md="8" class="d-flex flex-column">
               <h2>Such a fabulous idea that works!</h2>
               <p class="font-italic my-6 body-size">
                  “I use Red Sparrow Books as a tool in the salon when cutting kids hair and it’s fantastic. ‘Going to
                  the Hairdresser’ has images and interactive sounds so the child is aware of what to expect with each
                  new step. This has made the experience for myself, parents and the child more fun and enjoyable.”
               </p>
               <h3>Dani – Tantrum Hair Design.</h3>
            </v-col>
            <v-spacer />
         </v-row>
      </v-container>
   </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
   data() {
      return {};
   },
   computed: {
      ...mapState(["assets"]),
      widthStyle() {
         return this.$vuetify.breakpoint.smAndUp ? { width: "45vw" } : { width: "65vw" };
      },
   },
   methods: {},
};
</script>

<style lang="scss" scoped>
.cover-overlay {
   background: #ff7c7c;
   position: absolute;
   top: 0px;
   left: 0px;
   bottom: 0px;
   z-index: 2;
   width: 100%;
   height: 100%;
}

.title-image {
}
.iconOurvalues {
   border: 1px solid white;
   border-radius: 50%;
}
.imageAboutUs {
   border-radius: 50% 20% / 10% 40%;
}
</style>
